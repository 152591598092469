import React, { useEffect, useState } from "react";
import Layout from "../Component/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { GetTransactionHistory } from "../redux/auth/auth";
import { Button } from "react-bootstrap";

const TransactionHistoryAdmin = () => {
  const dispatch = useDispatch();

  const { TranscationHistoryData } = useSelector((state) => state.slice);

  const [itemPerPage, setitemPerPage] = useState(8);

  useEffect(() => {
    dispatch(GetTransactionHistory({ dispatch }));
  }, []);

  const handlechangeMore = () => {
    setitemPerPage((prev) => prev + 10);
  };

  const handlechangeLess = () => {
    setitemPerPage((prev) => prev - 10);
  };
  const sortedData =
    TranscationHistoryData?.slice().reverse() || [];
  return (
    <>

      <div className="container">
        <div className="row transaction_footer">
          <div className="col">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {/* <th>Id</th> */}
                    <th style={{ backgroundColor:"#0d6efd", color: "white" }}>Transaction Hash</th>
                    <th style={{ backgroundColor:"#0d6efd", color: "white" }}>Wallet Address</th>
                    <th style={{ backgroundColor:"#0d6efd", color: "white" }}>USDT Amount</th>
                    <th style={{ backgroundColor:"#0d6efd", color: "white" }}>IFF Amount</th>
                    <th style={{ backgroundColor:"#0d6efd", color: "white" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.slice(0, itemPerPage)?.map(
                    (value, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td>{value?.tx_hash}</td>
                        <td>{value?.address}</td>
                        <td>{value?.amount}</td>
                        <td>{value?.iff_token}</td>
                        <td>{value?.status}</td>
                      </tr>
                    )
                  )}

                </tbody>
                <div>

                  {itemPerPage < TranscationHistoryData?.length ? (
                    <div className="py-3">
                      <Button style={{ width: "200px" }} onClick={handlechangeMore}>
                        + See More
                      </Button>

                      {itemPerPage > 10 ? (
                        <Button className="my-2" style={{ width: "200px" }} onClick={handlechangeLess}>
                          - See Less
                        </Button>

                      ) : null}
                    </div>
                  )

                    : itemPerPage > 10 ? (
                      <Button style={{ width: "200px" }} onClick={handlechangeLess}>
                        - See Less
                      </Button>
                    ) : null}


                  {/* { itemPerPage ?(
                  <Button onClick={handlechangeMore} style={{width:"200px"}}>Show More</Button>
                ) : (
                  <Button onClick={handlechangeLess}  style={{width:"200px"}}>Show Less</Button>
                )} */}
                </div>


              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistoryAdmin;
