import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Video1 from "../Assests/Images/airdrop.webm";
import InactiveAir from "../Assests/Images/Inactive_Airdrop.png";
import { useNavigate } from 'react-router-dom';
import RewardSection from '../Component/reward/RewardIff';
import { useDispatch, useSelector } from 'react-redux';
import { GetAirDropHandler } from '../redux/auth/auth';

function Airdrop() {
  const videoRefs = useRef([]);
  const naviagte = useNavigate()
  const dispatch = useDispatch()
  const [airDropData, setAirDropData] = useState()
  const { GetAirdropData } = useSelector((state) => state.slice);
  useEffect(() => {
    dispatch(GetAirDropHandler({ dispatch }));
  }, []);


  const filterdData = GetAirdropData?.body?.filter((value) => {
    if (value?.status === "ACTIVE") {
      return value
    }

  });


  console.log(filterdData, 'airDropDataairDropData');

  const handleMouseEnter = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].play();
    }
  };

  const handleMouseLeave = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].pause();
      videoRefs.current[index].currentTime = 0;
    }
  };

  const AirdropContent = [
    {
      heading: 'Bronze Airdrop',
      text: `Boost Your IFF Holdings! Acquire a minimum of 250 IFF and enjoy an additional 1% reward! Please note: T&C apply.`,
      token: 250,
      video: Video1,
      activeStatus: 'true',
      airdropValue: 1
    },
    {
      heading: 'Silver Airdrop',
      text: `Silver Lining for IFF Enthusiasts! Hold 500 IFF or more to unlock a generous 2% bonus! Please note: T&C apply.`,
      token: 500,
      video: Video1,
      activeStatus: 'true',
      airdropValue: 2
    },
    {
      heading: 'Gold Airdrop',
      text: `Golden Opportunities Await! For those with 1000 IFF or above, access an impressive 5% extra reward! Please note: T&C apply.`,
      token: 1000,
      video: Video1,
      activeStatus: 'true',
      airdropValue: 5

    },
    {
      heading: 'Media+ Airdrop',
      text: '',
      token: 1000,
      video: Video1,
      activeStatus: 'false'
    },
    {
      heading: 'VIP Airdrop',
      text: '',
      token: 1000,
      video: Video1,
      activeStatus: 'false'
    },
    {
      heading: 'Play to Earn',
      text: '',
      token: 1000,
      video: Video1,
      activeStatus: 'false'
    },
  ];
  const handlNaviGateBuypage = (value) => {
    naviagte(`/buyifftoken/${value}`);
  };

  return (
    <div  >
      <div className='d-flex justify-content-evenly airdrop_resp row'>
        {AirdropContent.map((value, index) => {
          return (
            <div key={index} className='col-lg-4 col-sm-12 mt-4'>
              <Card className='airdrop_card'>
                {value.activeStatus === 'true' ? (
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    width="100%"
                    muted
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                  >
                    <source src={value.video} type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={InactiveAir} alt="Inactive" />
                )}
                <Card.Body>
                  <Card.Title>{value.heading}</Card.Title>
                  <Card.Text>{value.text}</Card.Text>
                  <Button variant="primary" style={value.activeStatus === 'false' ? { backgroundColor: "#61a1fe", color: "white" } : {}}
                    onClick={() => {
                      if (value.activeStatus === 'true') {
                        handlNaviGateBuypage(value.token);
                      }
                    }}>
                    {value.activeStatus === 'true' ? "Claim" : "Coming soon..."}
                  </Button>
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </div>

    </div>
  );
}

export default Airdrop;
