import React, { useEffect, useState } from "react";
import "../../Assests/global.css";
import logoimg from "../../Assests/Images/newIff.png";
import Web3 from "web3";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { ethers } from "ethers";
import { useAccount, usePublicClient } from 'wagmi';
import { Button } from "react-bootstrap";
import IFF from "../../Assests/Images/ifflogo.png";
import BUSDICON from "../../Assests/Images/usdt.jpg";
import WALLET from "../../Assests/Images/walletbc.avif";
import { useDispatch, useSelector } from "react-redux";
import { useBalance } from 'wagmi'
import { IFFTokenHandler, fetchIFFTokenBalance, fetchUsdtBalance, sidebarhandler } from "../../redux/auth/auth";
import MetaMaskModalOpen from "../MetaMaskModal/MetaMaskModal";
import { contractABI, contractAddress, usdtContractAbi, usdtContractAddress } from "../../common/common";

const Header = () => {
  const dispatch = useDispatch();
  const { address } = useAccount();
  const addsidebar = useSelector((state) => state.slice.add);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isMobileView1, setIsMobileView1] = useState(window.innerWidth > 767);

  const publicClient = usePublicClient();

  const [account, setAccount] = useState('');
  const { IFFToken, usdtBalance } = useSelector((state) => state.slice);
  const navigate = useNavigate();

  const { data: balanceData } = useBalance({ address });

  useEffect(() => {
    if (address) {
      setAccount(address);
      localStorage.setItem('meta', address);
    } else {
      localStorage.removeItem('meta');
      navigate('/');
    }
  }, [address]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsMobileView1(window.innerWidth > 767);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const displayStyle = {
    display: isMobileView ? 'block' : 'none'
  };

  const displayStyle1 = {
    display: isMobileView1 ? 'block' : 'none'
  };

  const truncatedAddress = account ? account.slice(0, 8) + "..." + account.slice(-8) : '';

  const logoutHandle = () => {
    localStorage.clear();
    navigate('/');
  };

  useEffect(() => {
    if (address) {
      dispatch(fetchUsdtBalance({ dispatch }));
      dispatch(fetchIFFTokenBalance({ dispatch }));
    }
  }, [address, dispatch]);

  const sidebarfunction = () => {
    dispatch(sidebarhandler(!addsidebar));
  };

  useEffect(() => {
    if (addsidebar) {
      document.body.classList.add("toggle_icon");
    } else {
      document.body.classList.remove("toggle_icon");
    }
  }, [addsidebar]);

  return (
    <>
      <section className="wallet-header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="d-flex align-items-center ">
            <Link to='/'>
              <div style={{ display: 'flex' }}>
                <img src={IFF} alt="" height={32} style={{ marginTop: '5px' }} />
                <img src={logoimg} alt="" height={34} />
              </div>
            </Link>
          </div>
          <div className="d-flex d_flex_top">
            <div style={displayStyle} className="dropdown_css dropdown_custom">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div>
                      <img src={IFF} height={30} /> {IFFToken ? `${Number(IFFToken).toFixed(2)} IFF` : '0 IFF'}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div>
                      <img src={BUSDICON} height={30} /> {usdtBalance ? usdtBalance : 0} USDT
                    </div>
                  </Dropdown.Item>
                  {address && (
                    <Dropdown.Item>
                      <div>
                        <img src={WALLET} height={40} />
                        {truncatedAddress}
                      </div>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            &nbsp;
            <button className="btndot" onClick={sidebarfunction}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>

          <div className="navbar_eth_spw_css">
            <div className="d-flex d_flex_top">
              <div className="p-3 img_p3 d-flex">
                {localStorage.getItem("role") && <Button style={{ marginRight: '10px' }} onClick={logoutHandle}>
                  Logout
                </Button>}

                <div className="mt-2">
                  <ConnectButton />
                </div>
              </div>
              <div style={displayStyle1} className="dropdown_css dropdown_custom">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div>
                        <img src={IFF} height={30} />{" "}
                        {IFFToken
                          ? `${Number(IFFToken).toFixed(2)} IFF`
                          : "0 IFF"}
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div>
                        <img src={BUSDICON} height={30} /> {usdtBalance ? usdtBalance : 0} USDT
                      </div>
                    </Dropdown.Item>
                    {address && (
                      <Dropdown.Item>
                        <div>
                          <img src={WALLET} height={40} />
                          {truncatedAddress}
                        </div>
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};

export default Header;