import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  const { Component } = props;




  return (
    <>
      <Component />
    </>
  );
};

export default ProtectedRoute;
