import React from 'react'
import Dark from '../../Assests/Images/Dark.png'
import Orange from '../../Assests/Images/Orange.png'
import Improve from '../../Assests/Images/Improve.png'
import WhiteCard from '../../Assests/Images/wh.png'
import Purple from '../../Assests/Images/Purple.png'
import Science from '../../Assests/Images/Science.png'

const CardDash = () => {
    return (
        <div>
            <div className="row align-item-center-flex card_item_div">
                <div className="col-lg-6 aos-init aos-animate " data-aos="zoom-in" data-aos-duration="3000">
                    <div className="common-four div_card_main">
                        <img className="common-four-img" src={Dark} style={{ height: '25px', width: '25px' }} />
                        <p className="common-four-p change_lang" key="Open_Source_key">Decentralized Governance </p>
                        <p className="common-four-p1 change_lang" key="Toda_a_transação">Our platform embraces a decentralized governance model, giving stakeholders a voice in decision-making processes. There is no central authority; the community collectively guides the platform’s direction.</p>
                        {/* <p className="common-four-p1 change_lang" key="Não_precisa_de">Não precisa de se preocupar, você está a confiar o seu ETH a um algorítmo executado em uma rede blockchain descentralizada, sustentando um intercâmbio que ocorre sem nenhum envolvimento humano enquanto a rede ethereum existir</p>
                        <p className="common-four-p1 change_lang" key="Bem-vindo-à">Bem-vindo à criptomoeda.</p>
                        <p className="common-four-p1 change_lang" key="Seus_tokens_estão">Seus tokens estão seguros, ou alguém estaria gritando conosco até agora.</p> */}
                    </div>
                </div>
                <div className="col-lg-6 aos-init aos-animate " data-aos="zoom-in" data-aos-duration="3000">
                    <div className="common-four div_card_main">
                        <img className="common-four-img" src={Orange} />
                        <p className="common-four-p change_lang" key="Valorização_da_SPW">Transparent and Immutable </p>
                        <p className="common-four-p1 change_lang" key="Por_cada_SPW">Every action on our platform is recorded on the blockchain, ensuring transparency and immutability. Your contributions and interactions are securely stored and unalterable, providing peace of mind and security.</p>
                        {/* <p className="common-four-p1 change_lang" key="Quando_os_utilizadores">Quando os utilizadores compram, as tuas SPW valem um pouco mais, quando vendem, um pouco menos.</p>
                        <p className="common-four-p1 change_lang" key="Esta_exchange_faz">Esta exchange faz isto por ti! Não tens que te preocupar pelo preço.</p> */}
                    </div>
                </div>
                <div className="col-lg-6 aos-init " data-aos="zoom-in" data-aos-duration="3000">
                    <div className="common-four div_card_main last-pp">
                        <img className="common-four-img" src={Purple} />
                        <p className="common-four-p change_lang" key="Notas_finais">Community-Driven Development </p>
                        <p className="common-four-p1 change_lang" key="Gerenciado_por">We highly value community input and actively encourage participation in platform development. Feedback, suggestions, and contributions are all crucial in shaping the future of our ecosystem.</p>
                        {/* <p className="common-four-p1 change_lang sky-b" key="Preços_autom">Preços automáticos</p>
                        <span className="change_lang sky-b" key="O_valor_está_diretamente">O valor está diretamente relacionado a quantos tokens existem a qualquer momento.</span>
                        <p className="common-four-p1 change_lang" key="Ganho_baseado_em">Ganho baseado em volume</p>
                        <span className="change_lang sky-b" key="de_todo_o_volume_é">15% de todo o volume é considerado como taxa e é automaticamente dividido como Eth para todos os titulares de token.</span>
                        <p className="common-four-p1 change_lang" key="Participação_em_key">Participação em renda ativa</p>
                        <span className="change_lang sky-b" key="O_sistema_Masternode_permite">O sistema Masternode permite recrutar mais e obter recompensas instantâneas de ETH. Além disso, todos ganham com o volume!</span>
                        <p className="common-four-p1 change_lang" key="Não_é_um_token">Não é um token 'Conceito'</p>
                        <span className="change_lang sky-b lst-sky" key="Cada_token_tem">Cada token tem valor intrínseco, pois cada token é apoiado pelo eth já existente no contrato (tente encontrar outra moeda que faça isso).</span> */}
                        <p className="common-four-p1"><span></span></p>
                    </div>
                </div>
                <div className="col-lg-6 aos-init aos-animate " data-aos="zoom-in" data-aos-duration="3000">
                    <div className="common-four div_card_main">
                        <img className="common-four-img" src={Improve} />
                        <p className="common-four-p change_lang" key="Open_Source_key">Innovative Incentive Mechanisms </p>
                        <p className="common-four-p1 change_lang" key="Toda_a_transação">Our platform rewards users for their contributions through cutting-edge incentive mechanisms. From token-based rewards to participation in governance activities, your efforts are always recognized and valued.</p>

                    </div>
                </div>
                <div className="col-lg-6 aos-init aos-animate " data-aos="zoom-in" data-aos-duration="3000">
                    <div className="common-four div_card_main">
                        <img className="common-four-img"  src={Science} />
                        <p className="common-four-p change_lang" key="Valorização_da_SPW">Continuous Improvement  </p>
                        <p className="common-four-p1 change_lang" key="Por_cada_SPW">We are dedicated to continuous improvement and evolution. Through regular updates, feedback loops, and community engagement, we constantly enhance the platform’s functionality and user experience.</p>

                    </div>
                </div>

                <div className="col-lg-6 div_card_main">
                    <div className="side-img-right aos-init" data-aos="fade-right" data-aos-duration="3000">
                        <img src={WhiteCard} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CardDash
