import React, { useEffect, useState } from "react";
import AdminModal from "../../Component/Admin/AdminModal";
import { useDispatch, useSelector } from "react-redux";
import { DeletePhaseApi, GetActiveRefundFun, GetPhaseHandler, UpdateRefundOption, UpdateStatusHandler } from "../../redux/auth/auth";
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { Button } from "react-bootstrap";

const PhaseToken = () => {
  const { GetPhaselist, refundStatus } = useSelector((state) => state.slice)
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false)
  const [editState, setEditState] = useState()
  const [addPhase, setAddphase] = useState({
    ifftoken: "",
    usdt: "",
    targetValue: "",
  });
  const navigate = useNavigate()

  const role = localStorage.getItem('role')
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (role == 'ADMIN') {
      navigate('/admindash')
    } else {
      navigate('/admin')
    }
  }, [role])
  useEffect(() => {
    dispatch(GetActiveRefundFun({ dispatch }))
  }, [])

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GetPhaseHandler({ dispatch }))
  }, [])

  const handleUpdate = (id) => {
    dispatch(UpdateStatusHandler({ id, dispatch }))
  }

  const handleDelete = (id) => {
    dispatch(DeletePhaseApi({ id, dispatch }))
  }
  const editHandle = (value) => {
    handleShow()
    setShowEdit(true)
    setEditState(value)
    setAddphase(
      {
        ifftoken: value?.iff_token,
        usdt: value?.usdt_balance,
        targetValue: value?.targetValue,
      }
    )
  }
  const handleAdd = () => {
    handleShow()
    setShowEdit(false)
    setEditState('')
    setAddphase(
      {
        ifftoken: "",
        usdt: "",
        targetValue: ""
      }
    )

  }
  const RefundStatusUpdate = (status) => {
    dispatch(UpdateRefundOption({ status, dispatch }))
  }
  return (
    <>
      <div style={{ minHeight: "461px" }}>
        <Button variant="primary" onClick={handleAdd} className="btn_resp">
          Add Phase
        </Button>
        <Button variant="primary" onClick={() => RefundStatusUpdate(refundStatus[0]?.status == '1' ? '0' : "1")} className="btn_resp" style={{ marginLeft: '20px' }}>
          Refund Option Currently {refundStatus[0]?.status == '1' ? 'Active' : "Inactive"}
        </Button>
        <div style={{ width: "95%" }}>
          <div>
            <AdminModal handleClose={handleClose}
              show={show} showEdit={showEdit}
              editState={editState}
              setAddphase={setAddphase}
              addPhase={addPhase}
            />
          </div>

          <table class="table table_resp my-3">
            <thead>
              <tr>
                <th scope="col">Phase</th>
                <th scope="col">IFF Amount</th>
                <th scope="col">USDT</th>
                <th scope="col">Target Value</th>
                <th scope="col" className="d-flex justify-content-center">
                  Status
                </th>
                <th scope="col">Action</th>

              </tr>
            </thead>
            <tbody>
              {GetPhaselist?.map((value, index) => {
                return (
                  <tr>
                    {" "}
                    <th scope="row">{index + 1}</th>
                    <td>{value?.iff_token}</td>
                    <td>{value?.usdt_balance}</td>
                    <td>{value?.targetValue}</td>
                    <td className="d-flex justify-content-center">
                      <button
                        className={value?.status === 'ACTIVE' ? "btn btn-success w-50 active_btn_resp" : "btn w-50 btn-dark"}
                        disabled={value?.status === 'ACTIVE'}
                        onClick={() => handleUpdate(value?.id)}
                      >
                        {value?.status}
                      </button>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" >
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => editHandle(value)} >Edit</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDelete(value.id)} >Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default PhaseToken;
