import React from 'react'
import Layout from '../Component/layout/Layout'
import InvestComponent from '../Component/investment/InvestComponent'

const InvestMent = () => {
    return (
        <>
            <InvestComponent />
        </>
    )
}

export default InvestMent
