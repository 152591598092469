import { createSlice } from "@reduxjs/toolkit";
import Web3 from "web3";
import { ethers } from 'ethers';
import axios from "axios";
import { toast } from "react-toastify";
import { useContractWrite, useWaitForTransactionReceipt, useAccount } from 'wagmi';

import { BASE_API_URL } from "../../common/common";

const initialState = {
  isLoading: false,
  getChatTrue: false,
  latestChat: false,
  LoginData: {},
  SummaryData: {},
  companyList: [],
  web3: "",
  usdtBalance: "",
  IFFToken: "",
  GetPhaselist: [],
  GetAirdropData: [],
  TranscationHistoryData: [],
  TranscationHistoryDataByAddress: [],
  ClaimData: [],
  UpdateClaimData: [],
  add: false,
  targetUser: [],
  targetUserAll: [],
  refundStatus: [],
  getClaimdata: [],
  keyPresentText: "",
};

const globalDataSlice = createSlice({
  name: "globalDataSlice",
  initialState,
  reducers: {
    hasError(state) {
      state.isLoading = false;
    },
    startLoading(state, actions) {
      state.isLoading = actions.payload;
    },
    setGetChatTrue(state, actions) {
      state.getChatTrue = actions.payload;
    },
    updateSuccessTransection(state, actions) {
      state.isLoading = false;
    },
    web3Handler(state, actions) {
      state.web3 = actions.payload;
    },
    IFFTokenHandler(state, actions) {
      state.IFFToken = actions.payload;
    },
    usdtBalanceHandler(state, actions) {
      state.usdtBalance = actions.payload;
    },
    sidebarhandler(state, actions) {
      state.add = actions.payload;
    },
    GetApiClaimData(state, actions) {
      state.getClaimdata = actions.payload;
    },
    GETIffstorehandler(state, actions) {
      state.GetPhaselist = actions.payload;
    },
    GETAirdropAdminHandler(state, actions) {
      state.GetAirdropData = actions.payload;
    },
    GetlatestuserHandler(state, actions) {
      state.targetUser = actions.payload;
    },
    GetlatestuserAllHandler(state, actions) {
      state.targetUserAll = actions.payload;
    },
    GetTransactionHistoryHandler(state, actions) {
      state.TranscationHistoryData = actions.payload;
    },
    GetTransactionHistoryHandlerByAddress(state, actions) {
      state.TranscationHistoryDataByAddress = actions.payload;
    },
    GetRefundStatusbtn(state, actions) {
      state.refundStatus = actions.payload;
    },
    GetClaimDataByAddress(state, actions) {
      state.ClaimData = actions.payload;
    },
    UpdateclaimHandler(state, actions) {
      state.UpdateClaimData = actions.payload;
    },
    GetUpdatedMessage(state, action) {
      state.keyPresentText = action.payload;
    }
  },
});

export default globalDataSlice.reducer;

export const {
  GetHomedataHandler,
  LoadingMessageHandler,
  startLoading,
  setGetChatTrue,
  updateSuccessTransection,
  web3Handler,
  IFFTokenHandler,
  usdtBalanceHandler,
  GETIffstorehandler,
  GETAirdropAdminHandler,
  GetTransactionHistoryHandler,
  GetTransactionHistoryHandlerByAddress,
  GetClaimDataByAddress,
  UpdateclaimHandler,
  sidebarhandler,
  GetUpdatedMessage
} = globalDataSlice.actions;

export const handleApproveAndTransfer = ({
  usdtContractAbi,
  usdtContractAddress,
  dispatch,
  usdtAmount,
  iffAmount,
  contractABI,
  contractAddress,
  claimaddreess,
  account
}) => async () => {
  if (!account) {
    toast.error("No account found. Please connect your wallet.");
    return;
  }
  try {
    dispatch(globalDataSlice.actions.startLoading(true));
    const { data, write, error } = useContractWrite({
      address: usdtContractAddress,
      abi: usdtContractAbi,
      functionName: 'transfer',
      args: ['0xB76Eae8A4A5Da11114FEBA9142a7Dc9827af7310', usdtAmount],
    });
    if (error) {
      toast.error(error.message);
      dispatch(globalDataSlice.actions.startLoading(false));
      return;
    }
    write();
    const { isSuccess, isLoading } = useWaitForTransactionReceipt({
      hash: data?.hash,
    });
    if (isLoading) {
      console.log("Transaction is loading...");
    }
    if (isSuccess) {
      dispatch(globalDataSlice.actions.startLoading(false));
      dispatch(
        IFFTranferHandler({
          dispatch,
          iffAmount,
          contractABI,
          contractAddress,
          usdtContractAbi,
          usdtAmount,
          usdtContractAddress,
          claimaddreess
        })
      );
      toast.success(`Successfully transferred ${usdtAmount} USDT`);
    } else {
      toast.error("Transaction failed");
    }
  } catch (error) {
    toast.error(error.message);
    dispatch(globalDataSlice.actions.startLoading(false));
  }
};

export const transferIFFHandler = ({
  contractABI,
  contractAddress,
  dispatch,
  web3,
  iffAmount,
  usdtAmount,
  usdtContractAbi,
  usdtContractAddress,
}) => async () => {
  const amount = iffAmount
  const account = localStorage.getItem("meta");
  if (web3 && account) {
    try {
      dispatch(globalDataSlice.actions.startLoading(true));
      const contract = new web3.eth.Contract(contractABI, contractAddress);
      const amountInWei = web3.utils.toWei(amount, 'ether');
      const recipientAddress = "0xc6a196Ab1350D55b9f4D2771AE0238140E283bef";
      const balance = await contract.methods.balanceOf(account).call();
      const allowance = await contract.methods.allowance(account, recipientAddress).call();
      const gasPrice = await web3.eth.getGasPrice();
      if (parseInt(balance) < parseInt(amountInWei)) {
        toast.error("Insufficient balance to cover transfer");
        throw new Error("Insufficient balance to cover transfer");
      }
      if (parseInt(allowance) < parseInt(amountInWei)) {
        console.log("Updating allowance...");
        const approveReceipt = await contract.methods
          .approve(recipientAddress, amountInWei)
          .send({ from: account });
        if (approveReceipt.transactionHash) {
          console.log("Allowance updated successfully.");
        } else {
          console.error("Error updating allowance:", approveReceipt);
          throw new Error("Error updating allowance");
        }
      }

      const nonce = await web3.eth.getTransactionCount(account);

      console.log(nonce, "nonce");

      const transferReceipt = await contract.methods
        .transfer(recipientAddress, amountInWei)
        .send({
          from: account,
          gas: 200000,
          gasPrice: gasPrice,
          nonce: nonce,
        });

      if (transferReceipt.transactionHash) {
        dispatch(SellIFFHandler({ dispatch, iffAmount, usdtAmount, web3, contractABI, contractAddress, usdtContractAbi, usdtContractAddress, }))
        console.log(transferReceipt.transactionHash, 'transferReceipt.transactionHash')
        toast.success(`Successfully transferred ${amount} IFF tokens`);
      } else {
        // dispatch(globalDataSlice.actions.hasError(error));
      }

      console.log(
        `Transfer transaction hash: ${transferReceipt.transactionHash}`
      );
      console.log(
        `Successfully transferred ${amount} IFF tokens to ${recipientAddress}`
      );
    } catch (error) {
      console.error("Error performing direct transfer:", error.message);
      toast.error(error.message);
      dispatch(globalDataSlice.actions.startLoading(false));
      toast.error(error);
    }
  }
};

export const IFFTranferHandler =
  ({
    dispatch,
    iffAmount,
    usdtAmount,
    web3,
    contractABI,
    contractAddress,
    usdtContractAbi,
    usdtContractAddress,
    claimaddreess
  }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      let account = localStorage.getItem("meta");
      let rewardedAmount = 0;
      try {
        let url = `${BASE_API_URL}/sendToken?amount=${iffAmount}&toAddress=${claimaddreess}`;
        const response = await axios.get(url);
        if (response?.data?.transactionHash) {
          let hash = response?.data?.transactionHash
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(
            AddTransactionHistory({ account, iffAmount, hash, usdtAmount, dispatch, Type: 'BUY' })
          );
          dispatch(
            fetchUsdtBalance({
              web3, dispatch, usdtContractAbi, usdtContractAddress, contractABI, contractAddress, account,
            })
          );
          dispatch(
            GetClaimByAddress({ dispatch, claimaddreess })
          );
          dispatch(
            fetchIFFTokenBalance({ web3, contractABI, contractAddress, dispatch })
          );
          toast.success(`Successfully transferred IFF token in your account`);
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`transation failed try after some time`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SellIFFHandler =
  ({
    dispatch,
    iffAmount,
    usdtAmount,
    web3,
    contractABI,
    contractAddress,
    usdtContractAbi,
    usdtContractAddress,
  }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      let account = localStorage.getItem("meta");
      console.log(account, 'accountaccountaccountsell')
      const name = "usdt";
      // const remainingAmount = usdtAmount - (usdtAmount * (deductionPercentage / 100));
      // console.log(remainingAmount, 'remainingAmountremainingAmount', usdtAmount)
      try {
        let url = `${BASE_API_URL}/sendToken?amount=${usdtAmount}&toAddress=${account}&tokenName=${name} `;
        const response = await axios.get(url);
        if (response?.data?.transactionHash) {
          console.log(response, "sellllllllllllllllllllllll");
          let hash = response?.data?.transactionHash
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(
            fetchUsdtBalance({
              web3, dispatch, usdtContractAbi, usdtContractAddress, contractABI, contractAddress, account,
            })
          );
          dispatch(
            fetchIFFTokenBalance({ web3, contractABI, contractAddress, dispatch })
          );
          dispatch(
            AddTransactionHistory({ account, iffAmount, hash, usdtAmount, dispatch, Type: 'SELL' })
          );
          toast.success(`Successfully transferred Usdt in your account `);
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`transation failed try after some time`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

//////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////// POST ADMIN IFF /////////////////////////////////////////////
export const AddPhaseHandler =
  ({ addPhase, dispatch }) =>
    async () => {
      const body_params = {
        usdt_balance: addPhase?.usdt,
        iff_token: addPhase?.ifftoken,
        targetValue: addPhase?.targetValue,
      };
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/addPhase`;
        const response = await axios.post(url, body_params);
        if (response?.data) {
          console.log(response, "responseresponseIFF");
          toast.success(`Add phase successfully `);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetPhaseHandler({ dispatch }));
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };
//////////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////  GETAPI ////////////////////////////////////////////////////////////
export const GetPhaseHandler =
  ({ dispatch }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/getAllPhase`;
        const response = await axios.get(url);
        if (response?.data) {
          console.log(response, "responseresponseIFF");
          dispatch(
            globalDataSlice.actions.GETIffstorehandler(response?.data?.body)
          );
          dispatch(globalDataSlice.actions.startLoading(false));
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };
////////////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////  Update API ////////////////////////////////////////////////////////////
export const UpdateStatusHandler =
  ({ id, dispatch }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/updatePhaseStatus/${id}`;
        const response = await axios.post(url);
        if (response?.data) {
          console.log(response, "responseresponseIFF");
          toast.success(`Successfully updated Phase `);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetPhaseHandler({ dispatch }));
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };
////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////// POST ADMIN API /////////////////////////////////////////////
export const AdminLoginApi =
  ({ formData, dispatch, navigate }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/login`;
        const response = await axios.post(url, formData);
        if (!response?.data?.error) {
          console.log(response, "responseresponseIFF");
          toast.success(` Admin Login Successfully `);
          dispatch(globalDataSlice.actions.startLoading(false));
          localStorage.setItem("role", response?.data?.body?.role);
          localStorage.setItem("token", response?.data?.body?.token);
          console.log("rolerolerole", response?.data?.body?.role)
          if (response?.data?.body?.role == 'ADMIN') {
            navigate("/admindash");
          }
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`please login with correct credentials`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };
//////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////// Update ADMIN API /////////////////////////////////////////////
export const UpdatePhaseApi =
  ({ addPhase, id, dispatch }) =>
    async () => {
      const data = {
        iff_token: addPhase?.ifftoken,
        usdt_balance: addPhase?.usdt,
        id: id,
      };
      console.log(data, "datadata");
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/updatePhaseDetails`;
        const response = await axios.post(url, data);
        if (!response?.data?.error) {
          console.log(response, "responseresponseIFF");
          toast.success(` updated Successfully `);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetPhaseHandler({ dispatch }));
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(` not updated `);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

//////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////// Delete ADMIN API /////////////////////////////////////////////
export const DeletePhaseApi =
  ({ id, dispatch }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/deletePhaseDetails/${id}`;
        const response = await axios.post(url);
        if (!response?.data?.error) {
          console.log(response, "responseresponseIFF");
          toast.success(` deleted Successfully `);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetPhaseHandler({ dispatch }));
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(` not deleted `);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };
//////////////////////////////////////////////////////////////////////////////////////////////////////////

// export const fetchIFFTokenBalance = ({ address, contractAddress, contractABI }) => {
//   return async (dispatch) => {
//     if (address && contractAddress && contractABI) {
//       try {
//         const provider = new ethers.providers.Web3Provider(window.ethereum);
//         const contract = new ethers.Contract(contractAddress, contractABI, provider);
//         const balanceInWei = await contract.balanceOf(address);
//         const balance = ethers.utils.formatUnits(balanceInWei, 18);
//         console.log(balance, 'ifffbalance')
//         dispatch(globalDataSlice.actions.IFFTokenHandler(balance));

//         // Dispatch action with the fetched balance
//         // dispatch({ type: 'FETCH_TOKEN_BALANCE_SUCCESS', payload: balance });
//       } catch (error) {
//         // Dispatch action to handle errors
//         dispatch({ type: 'FETCH_TOKEN_BALANCE_FAILURE', payload: error.message });
//         console.error("Error fetching token balance:", error);
//       }
//     }
//   };
// };

export const fetchIFFTokenBalance =
  ({ dispatch }) =>
    async () => {
      const address = localStorage.getItem('meta')
      console.log(address, 'console1')
      dispatch(globalDataSlice.actions.startLoading(true));
      if (address) {
        try {
          let url = `${BASE_API_URL}getIffBalance/${address}`;
          const response = await axios.get(url);
          console.log(response, 'console2')
          if (!response?.data?.error) {

            dispatch(globalDataSlice.actions.startLoading(false));
            console.log(response?.data?.body, 'responseresponseresponse')
            dispatch(globalDataSlice.actions.IFFTokenHandler(response?.data?.body / 1000000000000000000));
            // dispatch(GetPhaseHandler({ dispatch }));
            return response.data;
          } else {
            dispatch(globalDataSlice.actions.startLoading(false));
            // toast.error(` not deleted `);
            console.log('console2')

          }
        } catch (error) {
          dispatch(globalDataSlice.actions.startLoading(false));
          console.log(error, 'console3')
        }
      } else {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// export const fetchUsdtBalance =
//   ({
//     web3,
//     account,
//     usdtContractAbi,
//     usdtContractAddress,
//     dispatch,
//     contractABI,
//     contractAddress,
//   }) =>
//     async () => {
//       if (web3 && account) {
//         try {
//           const usdtContract = new web3.eth.Contract(
//             usdtContractAbi,
//             usdtContractAddress
//           );
//           const balanceMethod = usdtContract.methods.balanceOf;

//           if (!balanceMethod || typeof balanceMethod !== "function") {
//             console.error(
//               'Error: "balanceOf" method not found in USDT contract ABI.'
//             );
//             return;
//           }

//           const balanceInWei = await balanceMethod(account).call();
//           const balanceInUsdt = web3.utils.fromWei(
//             balanceInWei.toString(),
//             "ether"
//           );
//           console.log(balanceInUsdt, "balanceInUsdtbalanceInUsdt");

//           const converted_balance = balanceInUsdt * 1000000000000;
//           dispatch(globalDataSlice.actions.usdtBalanceHandler(converted_balance));
//           dispatch(
//             fetchIFFTokenBalance({ web3, contractABI, contractAddress, dispatch })
//           );
//         } catch (error) {
//           console.error("Error fetching USDT balance:", error.message);
//         }
//       }
//     };
// export const fetchUsdtBalance = ({ usdtContractAddress, usdtContractAbi }) => {
//   return async (dispatch) => {
//     const address = localStorage.getItem('meta')
//     if (address) {
//       try {
//         const provider = new ethers.providers.Web3Provider(window.ethereum);
//         const usdtContract = new ethers.Contract(usdtContractAddress, usdtContractAbi, provider);
//         const balanceInWei = await usdtContract.balanceOf(address);
//         const balanceInUsdt = ethers.utils.formatUnits(balanceInWei, 18);
//         dispatch(usdtBalanceHandler(balanceInUsdt))
//         console.log(balanceInUsdt, 'balanceInUsdtmyconsole');


//         dispatch({ type: 'FETCH_USDT_BALANCE_SUCCESS', payload: balanceInUsdt });
//       } catch (error) {

//         dispatch({ type: 'FETCH_USDT_BALANCE_FAILURE', payload: error.message });
//         console.error("Error fetching USDT balance:", error);
//       }
//     }
//   };
// };

export const fetchUsdtBalance =
  ({ dispatch }) =>
    async () => {
      const address = localStorage.getItem('meta')
      console.log(address, 'console1')
      dispatch(globalDataSlice.actions.startLoading(true));
      if (address) {
        try {
          let url = `${BASE_API_URL}getUsdtBalance/${address}`;
          const response = await axios.get(url);
          console.log(response, 'console2')
          if (!response?.data?.error) {
            dispatch(globalDataSlice.actions.startLoading(false));
            console.log(response?.data?.body, 'responseresponseresponse')
            dispatch(globalDataSlice.actions.usdtBalanceHandler(response?.data?.body / 1000000000000000000));
            return response.data;
          } else {
            dispatch(globalDataSlice.actions.startLoading(false));
            console.log('console2')
          }
        } catch (error) {
          dispatch(globalDataSlice.actions.startLoading(false));
          console.log(error, 'console3')
        }
      } else {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const handleclaimapprove =
  ({
    data, dispatch, claimaddreess
  }) =>
    async () => {
      console.log(data, "dataaa");
      dispatch(globalDataSlice.actions.startLoading(true));
      let account = localStorage.getItem("meta");
      let iffAmount = data
      try {
        let url = `${BASE_API_URL}/sendToken?amount=${data}&toAddress=${account}`;
        const response = await axios.get(url);
        let usdtAmount = 0
        if (response?.data?.transactionHash) {
          let hash = response?.data?.transactionHash;
          console.log(response, "responseresponseIFF");
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.success(`Successfully transferred IFF token in your account `);
          dispatch(AddTransactionHistory({ account, iffAmount, hash, usdtAmount, dispatch }))
          dispatch(UpdateClaimAmount({ dispatch, claimaddreess }))
          dispatch(GetClaimByAddress({ dispatch, claimaddreess }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`transation failed try after some time`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetAirDropHandler =
  ({ dispatch }) =>
    async () => {
      // dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/getUsdt`;
        const response = await axios.get(url);
        if (response?.data) {
          console.log(response.data.body, "airdrop----------");
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(globalDataSlice.actions.GETAirdropAdminHandler(response.data));
          return response.data.body;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`transation failed try after some time`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const AddAirdropHandler =
  ({ dispatch, addpercent }) =>
    async () => {
      // dispatch(globalDataSlice.actions.startLoading(true));
      console.log('console1')
      try {
        let url = `${BASE_API_URL}/addUsdt`;
        const response = await axios.post(url, addpercent);
        console.log('console2')

        if (response?.data) {
          toast.success(` Added Airdrop Successfully `);
          console.log(response, "responseresponseIFF");
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetAirDropHandler({ dispatch }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const EditAirdropHandler =
  ({ dispatch, addpercent }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/editUsdt`;
        const response = await axios.post(url, addpercent);
        if (response?.data) {
          toast.success(` Updated Airdrop Successfully `);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetAirDropHandler({ dispatch }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const UpdateAirDropHandler =
  ({ dispatch, id, status }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/updatePercentStatus/${id}/${status}`;
        const response = await axios.post(url);
        if (response?.data) {
          toast.success(`Successfully updated Airdrop `);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetAirDropHandler({ dispatch }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const deleteAirDropHandler =
  ({ dispatch, id }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/deleteUsdt/${id}`;
        const response = await axios.delete(url);
        if (!response?.data.error) {
          toast.success(`Successfully deleted Airdrop `);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetAirDropHandler({ dispatch }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(response?.data?.msg);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetTransactionHistory =
  ({ dispatch }) =>
    async () => {
      try {
        let url = `${BASE_API_URL}/getTxHistory`;
        const response = await axios.get(url);
        if (response?.data) {
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(globalDataSlice.actions.GetTransactionHistoryHandler(response.data.body))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const AddTransactionHistory =
  ({ account, iffAmount, hash, usdtAmount, dispatch, Type }) =>
    async () => {
      const body_params = {
        amount: usdtAmount,
        tx_hash: hash,
        address: account,
        iff_token: iffAmount,
        status: 'success',
        type: Type
      }
      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/createTxHistory`;
        const response = await axios.post(url, body_params);
        if (response?.data) {
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetTransactionHistory({ dispatch }));
          dispatch(GetTransactionHistoryByAddress({ dispatch, address: account }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetTransactionHistoryByAddress =
  ({ dispatch, address }) =>
    async () => {
      try {
        let url = `${BASE_API_URL}/getTxHistoryByAddress/${address}`;
        const response = await axios.get(url);
        if (response?.data) {
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(globalDataSlice.actions.GetTransactionHistoryHandlerByAddress(response.data))
          dispatch(Getlatestuser({ dispatch }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////




//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetClaimByAddress =
  ({ dispatch, claimaddreess }) =>
    async () => {
      try {
        let url = `${BASE_API_URL}/getClaimedInfo/${claimaddreess}`;
        const response = await axios.get(url);
        if (response?.data) {
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(globalDataSlice.actions.GetClaimDataByAddress(response.data.body))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const UpdateClaimAmount =
  ({ dispatch, claimaddreess }) =>
    async () => {
      try {
        let url = `${BASE_API_URL}/updateClaimedStatus/${claimaddreess}`;
        const response = await axios.post(url);
        if (response?.data) {
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetClaimByAddress({ dispatch, claimaddreess }))
          dispatch(globalDataSlice.actions.UpdateclaimHandler(response.data.body))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Getlatestuser =
  ({ dispatch }) =>
    async () => {
      const address = localStorage.getItem('meta')
      if (address) {
        try {
          let url = `${BASE_API_URL}/getLatestUserTarget/${address}`;
          const response = await axios.get(url);
          if (response?.data) {
            dispatch(globalDataSlice.actions.startLoading(false));
            console.log('resp', response?.data?.body);
            dispatch(globalDataSlice.actions.GetlatestuserHandler(response.data.body));
            return response.data.body;
          } else {
            dispatch(globalDataSlice.actions.startLoading(false));
            toast.error(`transation failed try after some time`);
          }
        } catch (error) {
          dispatch(globalDataSlice.actions.startLoading(false));
        }
      }
    };
export const GetlatestuserAll =
  ({ dispatch }) =>
    async () => {
      const address = localStorage.getItem('meta')
      try {
        let url = `${BASE_API_URL}/getAllLatestUserTarget`;
        const response = await axios.get(url);
        console.log(response, "responses");
        if (response?.data) {
          dispatch(globalDataSlice.actions.startLoading(false));
          console.log('resp', response?.data?.body);
          dispatch(globalDataSlice.actions.GetlatestuserAllHandler(response.data.body));
          return response.data.body;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`transation failed try after some time`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }

    };


export const GetActiveRefundFun =
  ({ dispatch, address }) =>
    async () => {
      try {
        let url = `${BASE_API_URL}/getRefundStatus`;
        const response = await axios.get(url);
        if (response?.data) {
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(globalDataSlice.actions.GetRefundStatusbtn(response.data?.body))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

export const UpdateRefundOption =
  ({ status, dispatch }) =>
    async () => {
      try {
        let url = `${BASE_API_URL}/updateRefundStatus/${status}`;
        const response = await axios.post(url);
        if (response?.data) {
          toast.success(`Successfully Updated Refund Option`);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetActiveRefundFun({ dispatch }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };


export const AddTargetAmountClaim =
  ({ datas, dispatch }) =>
    async () => {

      dispatch(globalDataSlice.actions.startLoading(true));
      try {
        let url = `${BASE_API_URL}/claimBonus`;
        const response = await axios.post(url, datas);
        if (response?.data) {
          console.log(response, "responseresponseIFF");
          // toast.success(`Added `);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(UpdateClaimStatus({ dispatch }));
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };



export const UpdateClaimStatus =
  ({ dispatch }) =>
    async () => {
      const address = localStorage.getItem('meta')
      try {
        let url = `${BASE_API_URL}/updateClaimStatus/${address}`;
        const response = await axios.post(url);
        if (response?.data) {
          // toast.success(`Successfully Updated`);
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(GetClaimStatusApi({ dispatch }));
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

export const GetClaimStatusApi =
  ({ dispatch }) =>
    async () => {
      const address = localStorage.getItem('meta')
      try {
        let url = `${BASE_API_URL}/getClaimStatus/${address}`;
        const response = await axios.get(url);
        if (response?.data) {
          dispatch(globalDataSlice.actions.startLoading(false));
          console.log(response.data?.body, 'response.data?.bodyresponse.data?.body')
          dispatch(globalDataSlice.actions.GetApiClaimData(response.data?.body))
          dispatch(Getlatestuser({ dispatch }))
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`error`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

export const IFFClaimTranferHandler =
  ({
    dispatch,
    web3,
    contractABI,
    contractAddress,
    datas
  }) =>
    async () => {
      dispatch(globalDataSlice.actions.startLoading(true));
      let account = localStorage.getItem("meta");
      let rewardedAmount = 0;
      try {
        let url = `${BASE_API_URL}/sendToken?amount=${datas?.claim_bonus}&toAddress=${account}`;
        const response = await axios.get(url);
        if (response?.data?.transactionHash) {
          let hash = response?.data?.transactionHash
          dispatch(globalDataSlice.actions.startLoading(false));
          dispatch(
            AddTargetAmountClaim({
              dispatch,
              datas
            })
          );
          dispatch(
            GetClaimByAddress({ dispatch, claimaddreess: account })
          );
          dispatch(
            fetchIFFTokenBalance({ web3, contractABI, contractAddress, dispatch })
          );
          toast.success(`Transferred IFF token in your account`);
          console.log(response?.data, "datassss")
          return response.data;
        } else {
          dispatch(globalDataSlice.actions.startLoading(false));
          toast.error(`transation failed try after some time`);
        }
      } catch (error) {
        dispatch(globalDataSlice.actions.startLoading(false));
      }
    };

export const updateKey = ({ encryptedData }) => async (dispatch) => {
  dispatch(globalDataSlice.actions.startLoading(true));
  let token = localStorage.getItem("token");
  let data = JSON.stringify({
    "key": encryptedData
  });
  try{
    let url = `${BASE_API_URL}/addOrUpdateKey`;
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      }
    }
    const response = await axios.post(url, data, requestOptions);
    console.log(response, "resp");
    if (response?.data) {
      toast.success(`Key updated successfully`);
      dispatch(globalDataSlice.actions.startLoading(false));
      return response.data;
    } else {
    dispatch(globalDataSlice.actions.startLoading(false));
    toast.error('Something went wrong');
   }
  } catch (error) {
    dispatch(globalDataSlice.actions.startLoading(false));
  }
}

export const getUpdatedKey = () => async(dispatch) => {
  dispatch(globalDataSlice.actions.startLoading(true));
  let token = localStorage.getItem("token");
  try{
    let url = `${BASE_API_URL}/checkForKey`;
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      }
    }
    const response = await axios.get(url, requestOptions);
    if (response?.data) {
      dispatch(GetUpdatedMessage(response?.data?.msg));
      dispatch(globalDataSlice.actions.startLoading(false));
      return response.data;
    } else {
      dispatch(globalDataSlice.actions.startLoading(false));
      toast.error('Something went wrong');
    }
  } catch (error) {
    dispatch(globalDataSlice.actions.startLoading(false));
  }
}

