import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import metamaskimg from "../../Assests/Images/metaimg.png"
import { toast } from 'react-toastify';


function MetaMaskModalOpen({ ethBalance, initWeb3 }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let data = localStorage.getItem("meta")


  const handleclicked = () => {
    if (!ethBalance) {
      initWeb3()
      handleClose()
    }
    else {
      handleClose()
    }
  }
  return (
    <>
      <button onClick={handleShow}>
        {data ? "Connected" : "Wallet"}
      </button>

      <Modal show={show} onHide={handleClose}  >
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body ><div>
          <div className='d-flex justify-content-center'>
            <img height={48} src={metamaskimg}></img>
            <button style={{ width: "200px" }} onClick={handleclicked}> {data ? "Connected" : "Connect "}</button>
            {/* onClick={!ethBalance ? initWeb3 : null}> */}
          </div>
        </div></Modal.Body>

      </Modal>
    </>
  );
}

export default MetaMaskModalOpen;