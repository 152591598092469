import React, { useEffect } from 'react';
import Layout from '../Component/layout/Layout';
import RightIcon from '../../src/Assests/Images/right-icon.png';
import Diomond from '../../src/Assests/Images/diamond.png';
import DiomondGray from '../../src/Assests/Images/gray-im.png';
import Airdrop from '../../src/Assests/Images/airdrop2.png';
import Test from '../../src/Assests/Images/test.png';
import ExplicarComponent from '../Component/home/ExplicarComponent';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PdfWhitePaper from '../Assests/pdf/Ledger_of_Infinity_White_Paper_Printer_Friendly_Version.pdf';
import { Link } from 'react-router-dom';

const Home = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
            easing: 'ease-in-out',
        });
    }, []);

    const handleOpenPdf = () => {
        window.open(PdfWhitePaper, '_blank');
    };

    return (
        <div>
            <div className='row '>
                <div className="col-lg-6" data-aos="fade-up">
                    <div className="common-banner aos-init aos-animate" data-aos="fade-down" data-aos-duration="3000">
                        <p className="banner-p change_lang" key="A_MAIS_PODEROSA">Are you relying on AI or search engines for your information?</p>
                        <p className="banner-p1 change_lang" key="Ao_ter_SPW_recebe"><b>Consider this: whoever controls the information controls what you know.</b> <br />With our platform, you can take charge of your knowledge. Here's what we offer:</p>
                        <ul className="ul-banner">
                            <li>
                                <p><img src={RightIcon} style={{ width: '100px', height: '10px' }} /></p><div className="sky-p-home change_lang" key="Open_Source"><b>Transparent Sources:</b> Every piece of information has a clear origin, ensuring you know where your knowledge comes from.</div><p></p>
                            </li>
                            <li>
                                <p><img src={RightIcon} style={{ width: '100px', height: '10px' }} /></p><div className="sky-p-home change_lang" key="Smart_Contract"><b>Customization:</b> Manage which information is stored and evaluate its accuracy.</div><p></p>
                            </li>
                            <li>
                                <p><img src={RightIcon} style={{ width: '100px', height: '10px' }} /></p><div className="sky-p-home change_lang" key="Preços_automáticos"><b>Trust Verification:</b> Assess the reliability of the information with ease, giving you confidence in its validity.</div><p></p>
                            </li>
                            <li>
                                <p><img src={RightIcon} style={{ width: '100px', height: '10px' }} /></p><div className="sky-p-home change_lang" key="Preços_automáticos"><b>Earn While You Learn: </b>Benefit financially as you access and share information.</div><p></p>
                            </li>
                        </ul>
                        <div className="bt-banner">
                            <button className="btn banner-btn change_lang explain_content" value="button" key="Começa_agora" onClick={handleOpenPdf}>Learn More</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="common-right aos-init aos-animate" data-aos="fade-down" data-aos-duration="3000">
                        <img className="diamond-img" src={Diomond} alt="Diamond" />
                    </div>
                </div>
            </div>

            <div className="col-lg-12" data-aos="zoom-in-up">
                <div className="image aos-init aos-animate" data-aos="zoom-in" data-aos-duration="3000">
                    <img src={DiomondGray} alt="Diamond Gray" />
                </div>
            </div>
            <div className="row align-item-center-flex">
                <div className="col-lg-6 aos-init" data-aos="fade-left" data-aos-duration="3000">
                    <div className="common-banner">
                        <p className="banner-p change_lang" key="Como_funciona">Unleash the Power of Decentralized AI Learning with IFF Token</p>
                        <p className="banner-p1 change_lang" key="Todas_as_transações">Discover the IFF token, the driving force behind the Ledger of Infinity Team (LIT) ecosystem. It’s not just a cryptocurrency; it’s a catalyst for innovation, growth, and community engagement. Through token sales and ICOs, the IFF token empowers our platform’s continuous development and expansion. Engage with us and reap rewards, unlock exclusive content, and partake in the future of AI education. For investors, it’s an opportunity to be at the forefront of decentralized AI learning. Join us in shaping the future with the IFF token</p>
                        <div className="bt-banner">
                            <Link to='/buyifftoken'><button className="btn banner-btn change_lang explain_content" value="button" key="Começa_agora_key">Buy Now</button></Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" data-aos="fade-right" data-aos-duration="3000">
                    <div className="common-right aos-init aos-animate">
                        <img className="diamond-img" src={Test} alt="Test" />
                    </div>
                </div>
            </div>
            <div className='row ' style={{ marginTop: '40px' }}>
                <div className="col-lg-7" data-aos="fade-up">
                    <div className="common-banner aos-init aos-animate" data-aos="fade-down" data-aos-duration="3000">
                        <p className="banner-p change_lang" key="A_MAIS_PODEROSA">Discover the Exclusive Advantages of Infinity Founds Tokens: Your Gateway to a World of Benefits</p>
                        <p className="banner-p1 change_lang" key="Ao_ter_SPW_recebe"><b>Key Features of IFF Tokens:</b> </p>
                        <ul className="ul-banner">
                            <li>
                                <p><img src={RightIcon} style={{ width: '100px', height: '10px' }} /></p><div className="sky-p-home change_lang" key="Open_Source"><b>Lifetime VIP Membership: </b>Holders of 1,000 or more IFF tokens become lifetime VIP members, unlocking exclusive benefits and privileges.</div><p></p>
                            </li>
                            <li>
                                <p><img src={RightIcon} style={{ width: '100px', height: '10px' }} /></p><div className="sky-p-home change_lang" key="Smart_Contract"><b>Access to Premium Content:</b> Use IFF tokens to access premium courses, tutorials, and projects, enhancing your learning experience.</div><p></p>
                            </li>
                            <li>
                                <p><img src={RightIcon} style={{ width: '100px', height: '10px' }} /></p><div className="sky-p-home change_lang" key="Preços_automáticos"><b>Earn Rewards:</b> Participate in platform activities and earn IFF tokens as rewards for your contributions to the community.</div><p></p>
                            </li>
                            <li>
                                <p><img src={RightIcon} style={{ width: '100px', height: '10px' }} /></p><div className="sky-p-home change_lang" key="Preços_automáticos"><b>Airdrop Opportunities: </b>Enjoy regular airdrops that reward active users and token holders with free IFF tokens.</div><p></p>
                            </li>
                        </ul>
                        <div className="bt-banner">
                            <Link to='/airdrop'><button className="btn banner-btn change_lang explain_content" value="button" key="Começa_agora_key">Airdrop Now </button></Link>                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="common-right aos-init aos-animate" data-aos="fade-down" data-aos-duration="3000" >
                        <img className="diamond-img" src={Airdrop} alt="Diamond" />
                    </div>
                </div>
            </div>
            <ExplicarComponent />

        </div>
    );
};

export default Home;
