import React from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import "../../Assests/global.css";

const Layout = ({ children, showheader, showsidebar }) => {
  const path = useLocation();

  return (
    <div className="main-wallet">
      <div className="">{path.pathname !== "/admin" && <Sidebar />}</div>
      {path.pathname !== "/admin" && <Header />}

      <div className="common-dasboard">
        <div className="container-fluid">
          <div className="row">

            <div className="col-12 layout_header resp_css_header"
            // style={{ marginTop: "20px" }}
            >
              {" "}
              {children}
            </div>
          </div>
        </div>
      </div>
      {path.pathname !== "/admin" && <Footer />}
    </div>
  );
};

export default Layout;
