import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { AddAirdropHandler, EditAirdropHandler, getUpdatedKey, updateKey } from "../../redux/auth/auth";
import "../../../src/Assests/global.css";
import { encryptHexString } from "../../common/common"

function AirDropModal({ show, handleClose, handleShow, setShow, setAddpercent, addpercent, updateShow, setUpdateShow }) {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState("");
  const [decreptedData, setDecreptedData] = useState("");
  let secretKey = "=====jwdkqjwhdkjqwkqjjwqkherger=====";
  const { keyPresentText } = useSelector((state) => state.slice);

  const handleChange = (e) => {
    setAddpercent({ ...addpercent, [e.target.name]: e.target.value });
    setErrors({});
  };

  const dispatch = useDispatch();

  const validation = () => {
    const errors = {};
    if (!addpercent.usdt_balance) {
      errors.usdt_balance = "please enter IFF Token";
    }
    if (!addpercent.percentage) {
      errors.percentage = "please enter Percentage";
    }
    setErrors(errors);
    return errors;
  };

  const handleAdd = () => {
    const errors = validation();
    if (Object.keys(errors).length === 0) {
      handleClose();
      setUpdateShow(false);
      dispatch(AddAirdropHandler({ dispatch, addpercent }));
      setAddpercent({
        usdt_balance: "",
        percentage: "",
      });
    }
  };

  const editUpdateHandle = () => {
    const errors = validation();
    if (Object.keys(errors).length === 0) {
      handleClose();
      setUpdateShow(false);
      dispatch(EditAirdropHandler({ dispatch, addpercent }));
      setAddpercent({
        usdt_balance: "",
        percentage: "",
        id: ""
      });
    }
  };
  console.log('lkelfe')
  const openAdd = () => {
    handleShow();
    setUpdateShow(false);
    setAddpercent({
      usdt_balance: "",
      percentage: "",
    });
  };

  const callApiWithEncryptedData = (encryptedData) => {
    dispatch(updateKey({ encryptedData }));
    setData('')

  };

  const handleButtonClick = () => {
    const encrypted = encryptHexString(data);
    if (encrypted) {
      callApiWithEncryptedData(encrypted);
      setData('');
      dispatch(getUpdatedKey({ dispatch }));
    }
  };

  return (
    <>
      <Button variant="primary" onClick={openAdd}>
        Add Airdrop
      </Button>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '100px' }}>
        <input style={{ marginLeft: '100px' }} onChange={(e) => setData(e.target.value)}></input>
        <button style={{ marginLeft: '20px' }} onClick={handleButtonClick}>Update Key</button>
        <h5 style={{ marginLeft: "40px" }}>{keyPresentText && keyPresentText + " you can update this key as you wish."}</h5>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Airdrop</Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ paddingBottom: "0px" }}>
          <div className="d-flex justify-content-around admin_modal_resp">
            <div style={{ margin: 0 }}>
              <h5>IFF Token:</h5>
              <input
                type="text"
                className="input-field"
                placeholder="Enter IFF Token"
                name="usdt_balance"
                value={addpercent?.usdt_balance}
                onChange={handleChange}
              ></input>
              <p style={{ color: "red" }}>{errors.usdt_balance}</p>
            </div>
            <div style={{ margin: 0 }}>
              <h5>Percentage:</h5>
              <input
                type="text"
                className="input-field"
                placeholder="Enter Percentage"
                name="percentage"
                value={addpercent?.percentage}
                onChange={handleChange}
              ></input>
              <p style={{ color: "red" }}>{errors.percentage}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updateShow ? editUpdateHandle : handleAdd}>
            {updateShow ? 'Update' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AirDropModal;
