import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { AddPhaseHandler, UpdatePhaseApi } from "../../redux/auth/auth";

function AdminModal({ show, handleClose, editState, showEdit, addPhase, setAddphase }) {

  const dispatch = useDispatch();

  const handlechange = (e) => {
    const { name, value } = e.target;
    setAddphase({ ...addPhase, [name]: value });
  };

  const handleclick = () => {
    handleClose();
    const id = editState?.id
    if (editState) {
      dispatch(UpdatePhaseApi({ addPhase, id, dispatch }))
    } else {
      dispatch(AddPhaseHandler({ addPhase, dispatch }));
    }
    setAddphase()

  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{showEdit ? "Update" : 'Add'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-around admin_modal_resp">
            <div>
              <h5>IFF Token:</h5>
              <input
                type="text"
                placeholder="Enter IFF Token"
                name="ifftoken"
                value={addPhase?.ifftoken}
                onChange={handlechange}
              ></input>
            </div>
            <div>
              <h5>USDT:</h5>
              <input
                type="text"
                placeholder="Enter Usdt Amount"
                name="usdt"
                value={addPhase?.usdt}
                onChange={handlechange}
              ></input>
            </div>
            </div>
            <div className="d-flex ml-4 admin_modal_resp" style={{ marginLeft: "17px" }}>
            <div>
              <h5>Target value:</h5>
              <input
                type="text"
                placeholder="Enter target value"
                name="targetValue"
                // value={addPhase?.targetValue}
                onChange={handlechange}
              ></input>
            </div>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleclick}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminModal;
