import React, { useState } from "react";
import sidebarimg1 from "../../Assests/Images/homeblue.png";
import sidebarimg2 from "../../Assests/Images/sidebar2.png";
import sidebarimg3 from "../../Assests/Images/sidebar3.png";
import sidebarimg4 from "../../Assests/Images/sidebar4.png";
import sidebarimg5 from "../../Assests/Images/sidebar5.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { CiAirportSign1 } from "react-icons/ci";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux/store";
import { sidebarhandler } from "../../redux/auth/auth";

const Sidebar = () => {
  const [hoveredItem, setHoveredItem] = useState("");
  let role = localStorage.getItem("role");
  let meta = localStorage.getItem("meta");
  const navigate = useNavigate();
  const addsidebar = useSelector((state) => state.slice.add)

  const pathname = useLocation()

  const sidebarfunction = () => {
    dispatch(sidebarhandler(!addsidebar))
  }
  const navgateHandle = (value) => {
    navigate(value);
    // if (meta) {
    // } else {
    //   alert('Please Connect Your Wallet');
    // }
    sidebarfunction(!addsidebar)
  };

  const handleEnter = (item) => {
    setHoveredItem(item);
  };

  const handleLeave = () => {
    setHoveredItem("");
  };
  const isActive = pathname?.pathname.includes('/buyifftoken');

  return (
    <>
      <section className="sidebar-fix">
        <div className="sidebar_ul_css">
          {role === "ADMIN" ? (
            <ul>
              <Link to="/admindash">
                <li>
                  <FaHome className={pathname.pathname === "/admindash" ? "sidebar_active_css my-2 " : "my-2"} />
                </li>
              </Link>
              <Link to="/transactionhistoryAdmin">
                <li>
                  <CiCreditCard1 className={pathname.pathname === "/transactionhistoryAdmin" ? "sidebar_active_css my-2 " : "my-2"} />

                </li>
              </Link>
              <Link to="/adminairdrop">
                <li>
                  <CiAirportSign1 className={pathname.pathname === "/adminairdrop" ? "sidebar_active_css my-2 " : "my-2"} />

                </li>
              </Link>
            </ul>
          ) : (
            <ul>

              <li onMouseEnter={() => handleEnter('Home')} onMouseLeave={handleLeave} onClick={() => navgateHandle('/')}>
                {/* <img src={sidebarimg1} height={55} alt="" /> */}
                <FaHome className={pathname.pathname === "/" ? "sidebar_active_css my-2 " : "my-2"} />
                {hoveredItem === 'Home' && <span className="link-name">Home</span>}
              </li>
              <li className="li_currsor" onMouseEnter={() => handleEnter('Airdrop')} onMouseLeave={handleLeave} onClick={() => navgateHandle('/airdrop')}>
                {/* <img src={sidebarimg4} height={55} alt="" /> */}
                <CiAirportSign1 className={pathname.pathname === "/airdrop" ? "sidebar_active_css my-2 " : "my-2"} />
                {hoveredItem === 'Airdrop' && <span className="link-name">Airdrop</span>}
              </li>
              <li className="li_currsor" onMouseEnter={() => handleEnter('Investment')} onMouseLeave={handleLeave} onClick={() => navgateHandle('/buyifftoken')}>
                {/* <img src={sidebarimg3} height={55} alt="" /> */}
                <CiCreditCard1 className={isActive ? "sidebar_active_css my-2" : "my-2"} />
                {hoveredItem === 'Investment' && <span className="link-name">Buy IFF Token</span>}
              </li>
              {/* <li className="li_currsor" onMouseEnter={() => handleEnter('History')} onMouseLeave={handleLeave} onClick={() => navgateHandle('/transactionhistory')}>

                <FaHistory className={pathname.pathname === "/transactionhistory" ? "sidebar_active_css my-2 " : "my-2"} />

                {hoveredItem === 'History' && <span className="link-name">History</span>}
              </li> */}
              
              {/* <li className="li_currsor" onMouseEnter={() => handleEnter('Logout')} onMouseLeave={handleLeave} onClick={() => navgateHandle('/')}>
                <img src={sidebarimg5} height={55} alt="" />
                {hoveredItem === 'Home' && <span className="link-name"></span>}
              </li> */}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default Sidebar;
