import React, { useEffect, useState } from "react";
import Layout from "../Component/layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { GetTransactionHistory, GetTransactionHistoryByAddress } from "../redux/auth/auth";
import { Button } from "react-bootstrap";

const TranscationHistory = () => {
  const [itemsperPage, setItemPerPage] = useState(8);

  const dispatch = useDispatch();

  const { TranscationHistoryDataByAddress, TranscationHistoryData } = useSelector(
    (state) => state.slice
  );
  const address = localStorage.getItem("meta");

  useEffect(() => {
    dispatch(GetTransactionHistoryByAddress({ dispatch, address }));
  }, [address]);

  useEffect(() => {
    dispatch(GetTransactionHistory({ dispatch }));
  }, []);

  const handlemore = () => {
    setItemPerPage((prev) => prev + 10);
  };

  const handleless = () => {
    setItemPerPage((prev) => prev - 10);
  };

  function formatTxHash(hash) {
    if (!hash) return '';
    if (hash.length <= 8) return hash;
    return `${hash.slice(0, 7)}...${hash.slice(-7)}`;
  }

  const sortedData = TranscationHistoryData?.slice().reverse() || [];
  console.log(TranscationHistoryData, 'TranscationHistoryData')
  return (
    <>
      <div className="container">
        <div className="row transaction_footer">
          <div className="col" style={{ padding: '0px', }} >
            <div className="table-responsive box-shadow-attractive" >
              <p className="Recent">Recent Transactions</p>
              <table className="table table-striped">
                <thead>
                  <tr>
                    {/* <th>Id</th> */}
                    <th style={{ backgroundColor: "#0d6efd", color: "white" }}>Transaction Hash</th>
                    <th style={{ backgroundColor: "#0d6efd", color: "white" }}>Wallet Address</th>
                    <th style={{ backgroundColor: "#0d6efd", color: "white" }}>USDT Amount</th>
                    <th style={{ backgroundColor: "#0d6efd", color: "white" }}>IFF Amount</th>
                    <th style={{ backgroundColor: "#0d6efd", color: "white" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData
                    ?.slice(0, 14)
                    ?.map((value, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td>{formatTxHash(value?.tx_hash)}</td>
                        <td>{formatTxHash(value?.address)}</td>
                        <td>{value?.amount}</td>
                        <td>{value?.iff_token}</td>
                        <td>{value?.status}</td>
                      </tr>
                    ))}
                </tbody>
                {/* <div className="py-3">
                  {itemsperPage <
                    TranscationHistoryDataByAddress?.body?.length ? (
                    <div>
                      <Button style={{ width: "200px" }} onClick={handlemore}>
                        See More +{" "}
                      </Button>
                      {itemsperPage > 10 ? (
                        <Button className="my-2" style={{ width: "200px" }} onClick={handleless}>
                          See Less -{" "}
                        </Button>
                      ) : null}
                    </div>
                  ) : itemsperPage > 10 ? (
                    <Button style={{ width: "200px" }} onClick={handleless}>
                      See Less -{" "}
                    </Button>
                  ) : null}
                </div> */}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranscationHistory;
