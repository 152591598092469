import React, { useEffect } from 'react'
import InvestmentModal from './InvestmentModal'
import InvestCard from './InvestCard'
import IFF from '../../Assests/Images/ifflogo.png'
import WALLET from '../../Assests/Images/walletbc.avif'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { GetClaimStatusApi } from '../../redux/auth/auth'

const InvestComponent = () => {
    const dispatch = useDispatch();
    const address = localStorage.getItem('meta')
    const { IFFToken, targetUser, getClaimdata } = useSelector((state) => state.slice);
    console.log(getClaimdata[0]?.claim_bonus, "bonusesbonusesbonuses", targetUser?.bonus);
    const cardData = {
        Heading: 'Total Invested',
        Image: IFF,
        amount: targetUser?.total_buy_token || 0,
        wallet: WALLET,
        claim: false,
    }
    const cardData1 = {
        Heading: 'Claimable VIP Bonus',
        Image: IFF,
        // amount: cardData?.claimBonus <= targetUser?.bonus ? targetUser?.bonus : 0,
        // amount: getClaimdata[0]?.claim_bonus >= targetUser?.bonus ? '0' : targetUser?.bonus,
        amount: Number(getClaimdata[0]?.claim_bonus) < Number(targetUser?.bonus) ? Number(targetUser?.bonus) : 0,
        // amount: targetUser?.bonus || '0',  
        wallet: WALLET,
        claim: true,
        claimBonus: getClaimdata[0]?.claim_bonus
    }
    const cardData3 = {
        Heading: 'Claimed VIP Bonus',
        Image: IFF,
        amount: getClaimdata[0]?.total_claim_bonus || 0,
        wallet: WALLET,
        claim: false,
        claimBonus: getClaimdata[0]?.claim_bonus
    }
    const cardData2 = {
        Heading: targetUser?.bonus == 1500 ? `Highest Level Reached` : `VIP ${targetUser?.phase ? targetUser?.phase : ''} Target`,
        Image: IFF,
        amount: targetUser?.target_value || 0,
        wallet: WALLET,
        claim: false
    }
    useEffect(() => {
        dispatch(GetClaimStatusApi({ dispatch }))
    }, [address])
    console.log(cardData1?.claimBonus, "bonuses");
    return (
        <div className='resp_cards'>
            <InvestmentModal />
            <div className='row resp_modal'>
                <div className='col-lg-3'>
                    <InvestCard cardData={cardData} />
                </div>
                <div className='col-lg-3'>
                    <InvestCard cardData={cardData1} />
                </div>
                <div className='col-lg-3'>
                    <InvestCard cardData={cardData3} />
                </div>
                <div className='col-lg-3'>
                    <InvestCard cardData={cardData2} />
                </div>
            </div>
        </div>
    )
}

export default InvestComponent
