import React from "react";
import pdfdownload from "../../Assests/pdf/Terms_and_Conditions.pdf";
import pdfdownload1 from "../../Assests/pdf/Privacy_Statement.pdf";
import pdfdownload2 from "../../Assests/pdf/Ledger_of_Infinity_White_Paper.pdf";
import "../../Assests/global.css";

const Footer = () => {
  const handledownloadTermandCondition = () => {

    const pdfFilePath = pdfdownload;

    // Create a link element
    const link = document.createElement("a");
    link.href = pdfFilePath;
    link.download = pdfdownload;

    // Trigger a click on the link to start the download
    link.click();
  };

  const handledownloadPrivacyandPolicy = () => {

    const pdfFilePath = pdfdownload1;

    // Create a link element
    const link = document.createElement("a");
    link.href = pdfFilePath;
    link.download = pdfdownload1;

    // Trigger a click on the link to start the download
    link.click();
  };

  const handledownloadWhitePaper = () => {

    const pdfFilePath = pdfdownload2;

    // Create a link element
    const link = document.createElement("a");
    link.href = pdfFilePath;
    link.download = pdfdownload2;

    // Trigger a click on the link to start the download
    link.click();
  };

  return (
    <>
      <section
        class="footer-cus "
        style={{
          background: "rgb(245 245 245)",
          position: "relative",
        }}
      >
        <div class="container-fluid ">
          <div class="row">
            <div class="col-12">
              <div className="privsyflex">
                {/* <p class="SPWALLET" style={{ color: "#4c4cd1" }}>
                  {" "}
                  <span className="resp_ft">© IFF-ICO</span>
                </p> */}
                <div>
                   <p
                    class="SPWALLET"
                    onClick={handledownloadWhitePaper}
                    style={{ cursor: "pointer", color: "#4c4cd1" }}
                  >
                    White Paper
                  </p>
                  </div>

                {/* <div
                  className="resp_privacyandterms"
                  style={{
                    justifyContent: "end",
                    display: "flex",
                  }}
                > */}
                <div>
                  <p
                    class="SPWALLET"
                    onClick={handledownloadPrivacyandPolicy}
                    style={{ cursor: "pointer", color: "#4c4cd1" }}
                  >
                    Privacy Policy
                  </p>
                  </div>
                  <div>
                  <p
                    class="SPWALLET1"
                    onClick={handledownloadTermandCondition}
                    style={{ cursor: "pointer", color: "#4c4cd1" }}
                  >
                    Terms & Conditions
                  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default Footer;
