import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAirDropHandler, UpdateAirDropHandler, deleteAirDropHandler } from "../redux/auth/auth";
import { Button, Dropdown } from "react-bootstrap";
import AirDropModal from "../Component/Airdrop/AirdropModal";

const Adminairdrop = () => {
  const { GetAirdropData } = useSelector((state) => state.slice);
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [addpercent, setAddpercent] = useState({
    usdt_balance: "",
    percentage: "",
    id: ''
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAirDropHandler({ dispatch }));
  }, []);

  const handleUpdate = (id, status) => {
    dispatch(UpdateAirDropHandler({ dispatch, id, status }));
  };

  const editHandle = (value) => {
    setUpdateShow(true)
    handleShow()
    setAddpercent({
      usdt_balance: value?.usdt_balance,
      percentage: value?.percentage,
      id: value?.id,
    })
  }

  const deleteHandle = (id) => {
    if (id) {
      dispatch(deleteAirDropHandler({ dispatch, id }));
    }
  }

  return (
    <>
      <div style={{ minHeight: "461px" }}>
        <div>
          <h1
            style={{ fontSize: "45px", fontFamily: "auto", fontWeight: "600" }}
          >
            Airdrop
          </h1>
          <div>
            <AirDropModal
              show={show}
              setShow={setShow}
              setAddpercent={setAddpercent}
              addpercent={addpercent}
              handleShow={handleShow}
              handleClose={handleClose}
              setUpdateShow={setUpdateShow}
              updateShow={updateShow}
            />
          </div>
          <div></div>
        </div>
        <table class="table table_resp my-3" style={{ width: "95%" }}>
          <thead>
            <tr>
              <th scope="col">Phase</th>
              <th scope="col">Discount</th>
              <th scope="col"> IFF Token</th>
              <th scope="col"> Status</th>
              <th scope="col"> Action</th>
            </tr>
          </thead>
          <tbody>
            {GetAirdropData?.body?.map((val, ind) => {
              return (
                <>
                  <tr>
                    <td>{ind + 1}</td>
                    <td>{val.percentage}%</td>
                    <td>{val.usdt_balance}</td>
                    <td>
                      <button
                        className={
                          val?.status === "ACTIVE"
                            ? "btn btn-success w-50 active_btn_resp"
                            : "btn w-50 btn-dark active_btn_resp"
                        }
                        onClick={() => handleUpdate(val?.id, val?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE")}
                      // disabled={val.status == "ACTIVE"}
                      >
                        {val.status}
                      </button>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" >

                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => editHandle(val)}>Edit</Dropdown.Item>
                          <Dropdown.Item onClick={() => deleteHandle(val.id)}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
      </div>
    </>
  );
};

export default Adminairdrop;
