import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import '@rainbow-me/rainbowkit/styles.css';

import Layout from "./Component/layout/Layout";
import Home from "./pages/Home";
import TranscationHistory from "./pages/TranscationHistory";
import InvestMent from "./pages/InvestMent";
import Airdrop from "./pages/Airdrop";
import AdminLogin from "./pages/admin/AdminLogin";
import PhaseToken from "./pages/admin/PhaseToken";
import ProtectedRoute from "./router/ProtectedRoute";
import Adminairdrop from "./pages/Adminairdrop";
import TransactionHistoryAdmin from "./pages/TransactionHistoryAdmin";
import ScrollToTop from "./Component/ScrollTop/ScrollTop";

function App() {
  const { isLoading } = useSelector((state) => state.slice);

  return (
    <div>
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div >
        <ToastContainer />
        <ScrollToTop />
        <Layout>
          <Routes>

            <Route path="/" element={<ProtectedRoute Component={Home} />} />
            <Route path="/transactionhistory" element={<ProtectedRoute Component={TranscationHistory} />} />
            <Route path="/transactionhistoryAdmin" element={<ProtectedRoute Component={TransactionHistoryAdmin} />} />
            <Route path="/buyifftoken/:value?" element={<ProtectedRoute Component={InvestMent} />} />
            <Route path="/airdrop" element={<ProtectedRoute Component={Airdrop} />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admindash" element={<ProtectedRoute Component={PhaseToken} />} />
            <Route path="/adminairdrop" element={<ProtectedRoute Component={Adminairdrop} />} />
          </Routes>
        </Layout>
      </div>
    </div>
  );
}

export default App;
