// Login.js

import React, { useEffect, useState } from "react";
import PhaseToken from "./PhaseToken";
import { useDispatch } from "react-redux";
import { AdminLoginApi } from "../../redux/auth/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import './Login.css';

const AdminLogin = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const role = localStorage.getItem('role')



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleLogin = () => {
        setFormData({ email: "", password: "" });
        dispatch(AdminLoginApi({ dispatch, formData, navigate }));
    };

    useEffect(() => {
        if (role == 'ADMIN') {
            navigate('/admindash')
        } else {
            navigate('/admin')
        }
    }, [role])

    return (
        <>
            <div className="login-container resp_admin_login">
                <h2>Login</h2>
                <form>
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="email"
                        value={formData.username}
                        onChange={handleChange}
                    />

                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        autoComplete="new-password"
                    />

                    <button type="button" onClick={() => handleLogin()}>
                        Login
                    </button>
                </form>
            </div>

            <div></div>
        </>
    );
};

export default AdminLogin;
