import React, { useEffect } from 'react'
import CardDash from '../dashboardCard/CardDash'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Xlogo from '../../Assests/Images/xlogo.png'
import Discord from '../../Assests/Images/discord.png'


const ExplicarComponent = () => {
    useEffect(() => {
        AOS.init({
            duration: 2000,
            once: true,
            easing: 'ease-in-out',
        });
    }, []);
    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-sect aos-init aos-animate" data-aos="fade-up" data-aos-duration="3000">
                        <p className="text-sect-p change_lang" key="Ainda_não_estás">Need more information?</p>
                        <p className="text-sect-p1 change_lang" key="Vamos_explicar_agora">Let me explore the details further!</p>
                    </div>
                </div>
            </div>
            <CardDash />
            <div style={{ right: 0, top: 0, padding: '10px' }}>
                <ul style={{ justifyContent: 'flex-end', display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
                    <li style={{ margin: '0 10px' }}>
                        <a href="https://discord.gg/pMtTZbXuaq" target="_blank" rel="noopener noreferrer">
                            <img src={Discord} alt="Discord" style={{ width: '50px', height: '50px' }} />
                        </a>
                    </li>
                    <li style={{ margin: '5px 10px' }}>
                        <a href="https://twitter.com/Ledger_Infinity" target="_blank" rel="noopener noreferrer">
                            <img src={Xlogo} alt="Twitter" style={{ width: '40px', height: '40px' }} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ExplicarComponent
