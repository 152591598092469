import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAirDropHandler,
  GetClaimByAddress,
  handleclaimapprove,
  Getlatestuser,
  AddTargetAmountClaim,
  IFFClaimTranferHandler,
  GetClaimStatusApi,
} from "../../redux/auth/auth";
import Web3 from "web3";
import { contractABI, contractAddress } from "../../common/common";

const InvestCard = ({ cardData }) => {
  const [handlepercent, setHandlePercent] = useState({
    usdt_balance: "",
    percentage: "",
    id: "",
    status: "",
  });

  let staticpercentage = {
    usdt_balance: 500,
    percentage: 5,
    id: 1,
    status: "ACTIVE",
  };

  const { GetAirdropData, ClaimData, getClaimdata, targetUser } = useSelector((state) => state?.slice);

  const dispatch = useDispatch();
  let data = ClaimData.map((val) => val?.pVal);

  let claimaddreess = localStorage.getItem("meta");
  const datas = {
    claim_bonus: targetUser?.bonus?.toString(),
    plan_value: targetUser?.target_value?.toString(),
    iff_token: targetUser?.total_buy_token?.toString(),
    address: claimaddreess
  }

  const handleclaim = () => {
    dispatch(IFFClaimTranferHandler({
      dispatch,
      Web3,
      contractABI,
      contractAddress,
      datas
    }))

  };

  useEffect(() => {
    dispatch(GetAirDropHandler({ dispatch, claimaddreess }));
    dispatch(GetClaimByAddress({ dispatch, claimaddreess }));
    dispatch(Getlatestuser({ dispatch }));
  }, [dispatch]);

  // const disableClaim =
  //   ClaimData[0]?.total_amount === 500 ||
  //   ClaimData[0]?.total_amount === 1000 ||
  //   ClaimData[0]?.total_amount === 2000 ||
  //   ClaimData[0]?.total_amount === 3000;

  const disableClaim =
    cardData?.claimBonus === 0 ||
    !claimaddreess ||
    cardData?.claimBonus >= targetUser?.bonus ||
    !cardData?.claimBonus;

  function formatCurrency(value) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }
  useEffect(() => {
    dispatch(GetClaimStatusApi({ dispatch }))
  }, [claimaddreess])

  // console.log(!claimaddreess, "fff");
  // disabled={cardData?.claimBonus === 0 || !claimaddreess || cardData?.claimBonus >= targetUser?.bonus || !cardData?.claimBonus}

  return (
    <>
      <Card
        style={{
          width: "100%",
          padding: "30px",
          borderRadius: "20px ",
          height: "180px",
        }}
      >
        <Card.Body>
          <Card.Subtitle className="mb-2 text-muted">
            {cardData?.Heading}{" "}
            <img src={cardData?.wallet} height={25} width={25} />
          </Card.Subtitle>
          <Card.Text>
            <div style={{ display: "flex" }}>
              <img
                src={cardData?.Image}
                height={16}
                width={18}
                style={{ marginTop: "5px" }}
              />
              <span className="amount_invest">
                {/* <p>{formatCurrency(Number(cardData?.amount)?.toFixed(1))} IFF</p> */}
                <p>{formatCurrency(Number(cardData?.amount)?.toFixed(1))} IFF</p>
              </span>
            </div>
            <div className="p-4" style={{ marginLeft: "-36px" }}>
              {cardData.claim && (
                <div>
                  {/* {targetUser?.bonus}{" "} */}
                  <Button
                    className="button_claim"
                    disabled={cardData?.claimBonus === 0 || !claimaddreess || cardData?.claimBonus >= targetUser?.bonus || !cardData?.claimBonus}
                    onClick={handleclaim}
                  >
                    {" "}
                    Claim Bonus
                  </Button>{" "}
                </div>
              )}
              {/* {cardData.claim && <div> {ClaimData[0]?.new_iff_token.toFixed(2)?ClaimData[0]?.new_iff_token.toFixed(2): ClaimData[0]?.total_iff_token.toFixed(2)*staticpercentage.percentage/100} <Button className='button_claim' disabled={ClaimData[0]?.total_iff_token<=400} onClick={handleclaim}> Claim IFF</Button></div>} */}
              {/* {cardData.claim && <div><span>{Number(cardData.amount).toFixed(2) * handlepercent.percentage / 100 ? Number(cardData.amount) * handlepercent.percentage / 100 : Number(cardData.amount).toFixed(2) * staticpercentage.percentage / 100}</span><Button className='button_claim' disabled={cardData.amount<50 || cardData.amount<100000 || cardData.amount<1500} onClick={handleclaim}> Claim IFF</Button></div>} */}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default InvestCard;
